import { ComponentType } from 'react'
import { withErrorBoundary } from 'react-error-boundary'
import { sendError } from 'ui/lib/logger'
import { ErrorComponent } from './ErrorComponent'

export function withErrorBoundaryAndLogging<Props extends object>(component: ComponentType<Props>) {
  return withErrorBoundary(component, {
    FallbackComponent: ErrorComponent,
    onError: (error, info) =>
      sendError({
        error,
        message: error?.message || 'Something went wrong',
        context: { ...info },
      }),
  })
}
