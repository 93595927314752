import { FC, useEffect } from 'react'
import { Event } from 'api/cms'
import { useAxios } from 'api/lib'
import startOfToday from 'date-fns/startOfToday'
import { Events as EventsComponent, EventsCarousel, CardSection } from 'ui/components/content'
import { MainPageComponentProps } from '../ComponentProps'
import { useSlidesToShow } from '../useSlidesToShow'
import { withErrorBoundaryAndLogging } from '../withErrorBoundaryAndLogging'

const MAX_EVENTS_IN_THE_COMPONENT = 5

const useEvents = (minEventDate: Date = startOfToday(), limit = MAX_EVENTS_IN_THE_COMPONENT) => {
  const url = new URLSearchParams({
    limit: limit.toString(),
    minEventDate: minEventDate.toISOString(),
  })
  return useAxios<Event[]>(`/api/events?${url}`)
}

const EventsImplementation: FC<MainPageComponentProps> = ({ isLoading, onReady }) => {
  const slidesToShow = useSlidesToShow()
  const { data: events = [], isLoading: isEventsLoading } = useEvents()

  useEffect(() => {
    if (!isEventsLoading) {
      onReady?.()
    }
  }, [isEventsLoading])

  return (
    <CardSection
      title="Events"
      href={events.length > 0 ? '/events' : undefined}
      isLoading={isLoading}
    >
      <EventsComponent
        events={events}
        isLoading={isLoading || isEventsLoading}
        className="hidden xl:flex"
      />
      <EventsCarousel
        events={events}
        isLoading={isLoading || isEventsLoading}
        slidesToShow={slidesToShow}
        className="xl:hidden"
      />
    </CardSection>
  )
}

export const Events = withErrorBoundaryAndLogging(EventsImplementation)
