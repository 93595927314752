import { FC, useEffect } from 'react'
import { useAxios } from 'api/lib'
import { UserSocialProfile } from 'api/src/dto'
import { Card } from 'ui/components/content'
import { Profile } from './Profile'
import { ProfileLoadingState } from './ProfileLoadingState'
import { MainPageComponentProps } from '../../ComponentProps'
import { withErrorBoundaryAndLogging } from '../../withErrorBoundaryAndLogging'

const useProfile = (userId: number) =>
  useAxios<UserSocialProfile>(`/api/users/${userId}/social-profile`)

type ProfileProps = MainPageComponentProps & {
  className?: string
  userId: number
}

const MyProfileDesktopImplementation: FC<ProfileProps> = ({
  className,
  userId,
  isLoading,
  onReady,
}) => {
  const { data: profile, isLoading: isUserProfileLoading } = useProfile(userId)

  useEffect(() => {
    if (!isUserProfileLoading) {
      onReady?.()
    }
  }, [isUserProfileLoading])

  return (
    <Card className={className}>
      {isLoading || isUserProfileLoading ? <ProfileLoadingState /> : <Profile profile={profile} />}
    </Card>
  )
}

export const MyProfileDesktop = withErrorBoundaryAndLogging(MyProfileDesktopImplementation)
