import { FC, useState } from 'react'
import { PostTargetType } from '@amityco/js-sdk'
import { AmityUiKitFeedWithProvider } from 'amity/components'
import { useRoutingContext } from 'ui/lib/navigation'
import { withErrorBoundaryAndLogging } from '../withErrorBoundaryAndLogging'

const AmityFeedImplementation: FC = () => {
  const { baseUrl, push } = useRoutingContext()
  const [isAmityFeedLoaded, setIsAmityFeedLoaded] = useState(false)
  return (
    <section className="gap-4 xl:col-span-2 xl:col-start-2 xl:row-start-2">
      {isAmityFeedLoaded && (
        <div className="text-deep-teal-900 mb-2 text-lg font-semibold xl:text-xl">
          Content & Commentary
        </div>
      )}
      <AmityUiKitFeedWithProvider
        router={{ push }}
        baseUrl={baseUrl}
        onLoaded={() => setIsAmityFeedLoaded(true)}
        targetType={PostTargetType.GlobalFeed}
      />
    </section>
  )
}

export const AmityFeed = withErrorBoundaryAndLogging(AmityFeedImplementation)
